<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <el-card>
          <h1>修改密码</h1>
          <br />

          <div class="col-xl-12 mb-2">
            <el-input
              placeholder="输入旧密码"
              v-model="form.oldPassword"
            ></el-input>
          </div>

          <div class="col-xl-12 mb-2">
            <el-input
              placeholder="请输入新密码"
              type="password"
              v-model="form.newPassword"
            ></el-input>
          </div>

          <div class="col-xl-12 mb-2">
            <el-input
              placeholder="请重复新密码"
              type="password"
              v-model="repeatPassword"
            ></el-input>
          </div>

          <div class="col-xl-12 mb-2">
            <div class="my-button-div">
              <button
                class="btn btn-primary my-button"
                @click="changePassword()"
              >
                修改
              </button>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
      },
      repeatPassword: "",
    };
  },
  methods: {
    changePassword() {
      if (this.repeatPassword == this.form.newPassword) {
        axios({
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: sessionStorage.getItem("systemToken"),
          },
          url: "/api/user/changePassword", //后端请求的路径
          method: "post",
          params: {
            oldPassword: this.$md5(this.form.oldPassword),
            newPassword: this.$md5(this.form.newPassword),
          },
        }).then((response) => {
          if (response.status === 0) {
            this.$message({
              message: "密码修改成功！",
              type: "success",
            });
            this.form.newPassword = "";
            this.form.oldPassword = "";
            this.repeatPassword = "";
          } else {
            this.$message({
              message: "发生错误，原因：" + response.msg,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "错误：两次密码不一致！",
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.block {
  display: inline-block;
}

.myInput {
  display: inline-flex;
  margin-bottom: 8px;
}
.inputBox {
  width: 100%;
}
.labelBox {
  text-align: left;
  width: 30%;
  margin-top: 10px;
}
.my-button-div {
  width: 10rem;
  margin: auto;
}
.my-button {
  margin-top: 20px;

  width: 100%;
}
label {
  font-size: 20px;
}
.el-switch {
  margin-left: 20px;
}
</style>
